import React from 'react';
import ProjectCard from './ProjectCard';

function Projects() {
  const projects = [
    {
      name: 'Best Canned Food',
      description: 'The system includes features such as orders management system, write review, favorite, notifications, payment integration, my orders and vouchers.',
      imageUrl: '../assets/images/best.png',
      tags: ['PHP (Laravel)', 'React JS', 'Dart (Flutter)'],
      link: 'https://play.google.com/store/apps/details?id=app.bizzsync.bestcannedfood'
    },
    {
      name: 'You and Me Delivery',
      description: 'Fast and reliable delivery can significantly enhance customer satisfaction.',
      imageUrl: '../assets/images/uandme.png',
      tags: ['PHP (Laravel)', 'Dart (Flutter)'],
      link: 'https://play.google.com/store/apps/details?id=app.youandme.delivery'
    },
    {
      name: 'You and Me Office',
      description: 'A delivery office app allows the admin to efficiently manage pickups and deliveries.',
      imageUrl: '../assets/images/office.png',
      tags: ['PHP (Laravel)', 'Dart (Flutter)'],
      link: 'https://play.google.com/store/apps/details?id=app.youandme.office'
    },
    {
      name: 'Hi5',
      description: 'This app allows employees to check their attendance history, leave balances, company policies and important announcements are easily accessible to all UMG employees.',
      imageUrl: '../assets/images/hi5.png',
      tags: ['Python (Oodoo)', 'PHP (Laravel)', 'Dart (Flutter)'],
      link: 'https://play.google.com/store/apps/details?id=com.umg.hi5core'
    },
    {
      name: 'Bizzsync',
      description: 'The package supports a wide range of business functions, from inventory management to customer relationship management.',
      imageUrl: '../assets/images/bizzsync.png',
      tags: ['PHP (Laravel)', 'Dart (Flutter)'],
      link: 'https://play.google.com/store/apps/details?id=app.youandme.office'
    },
    {
      name: 'Buddha SuTaung',
      description: 'This is a prayer for Buddhists, especially for people in Myanmar. It includes both audio and video files. You can download the book and save it to your download manager.',
      imageUrl: '../assets/images/nav.png',
      tags: ['Kotlin'],
      link: '#'
    },
  ];

  return (
    <section id="project" className="bg-gray-100 py-12 rounded-lg">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">Projects</h2>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
